var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', {
    attrs: {
      "id": "bg"
    }
  }, [_c('app-bar'), _c('nav-bar'), _c('network-error-overlay', {
    attrs: {
      "show": _vm.API_OFFLINE
    }
  }), _c('v-main', [_c('router-view', {
    key: _vm.reloadKey,
    on: {
      "reload": _vm.reload
    }
  })], 1), _c('notification-snacks'), _c('dialogs'), _c('prescribe-launcher')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }