const Menu = [
  //{ name: 'Welcome', icon: '$home', moduleCode: null, route: '/' },
  { name: 'Lab Home', icon: '$home', moduleCode: 'home_lab', route: '/lab-home' },
  { name: 'File Inbox', icon: '$cloud-storage', moduleCode: 'incoming', route: '/incoming' },
  { name: 'Jobs', icon: '$briefcase', moduleCode: 'jobs', route: '/jobs' },
  { name: 'Customers', icon: '$customers', moduleCode: 'customers', route: '/customers' },
  { name: 'Despatch', icon: '$truck', moduleCode: 'dispatch', route: '/dispatch' },
  { name: 'Invoices', icon: '$invoice', moduleCode: 'invoices', route: '/invoices' },
  { name: 'Reports', icon: 'mdi-chart-line', moduleCode: 'view_reports', route: '/reports' },
];

export default Menu
