import router from '../router'
import Deferred from '@/helpers/Deferred'
import Cookies from 'js-cookie'

const NOTIFICATION_DURATION = 4500; // how long to display notifications

const UI = {
  namespaced: true,
  state: {
    app_bar: false,
    nav_bar: false,
    nav_bar_toggle: false, // toggled on/of while enabled
    notifications: [
    ],
    page_title: "",
    show_education: JSON.parse(Cookies.get('SHOW_EDU') || false),
    dialogQueue: [],
  },
  getters: {
    APP_BAR_VISIBLE: state => state.app_bar,
    NAV_BAR_VISIBLE: state => state.nav_bar,
    NOTIFICATIONS: state => state.notifications,
    NAV_BAR_TOGGLED: state => state.nav_bar_toggle,
    PAGE_TITLE: state => state.page_title,
    EDUCATION: state => state.show_education,
    DIALOG_QUEUE_LENGTH: state => state.dialogQueue.length,
  },
  mutations: {
    showAppBar(state, visible){
      state.app_bar = visible;
    },
    showNavBar(state, visible){
      state.nav_bar = visible;
    },
    toggleNavBar(state){
      state.nav_bar_toggle = !state.nav_bar_toggle;
    },
    addNotification(state, { message }){
      let notification = {
        text: message,
        key: Math.random(),
        age: Date.now(),
        show: true,
      };
      state.notifications.push(notification);
    },
    removeNotification(state, key){
      let index = state.notifications.findIndex(n => n.key == key);
      if(index < 0) return;
      if(state.notifications[index].show == true)
        state.notifications[index].show = false;
      else
        state.notifications.splice(index, 1);
    },
    clearNotifications(state){
      state.notifications = [];
    },
    setPageTitle(state, title){
      state.page_title = title;
      document.title = title ? `${title} - LaserCAM Cloud OMS` : 'LaserCAM Cloud OMS';
    },
    queueDialog(state, { dialog, callback }){
      let deferred = new Deferred();
      state.dialogQueue.push({
        dialog,
        deferred,
      });
      callback(deferred.promise);
    },
    dialogAdvanceQueue(state){
      state.dialogQueue.splice(0,1);
    },
    toggleEducation(state){
      state.show_education = !state.show_education;
      Cookies.set('SHOW_EDU', JSON.stringify(state.show_education));
    }

  },
  actions: {
    SHOW_NAVBARS({commit}){
      commit('showAppBar', true);
      commit('showNavBar', true);
    },
    HIDE_NAVBARS({commit}){
      commit('showAppBar', false);
      commit('showNavBar', false);
    },
    NAV_BAR_TOGGLE({commit}){
      commit('toggleNavBar');
    },
    NOTIFY({commit}, {message}){
      commit('addNotification', {message});
    },
    CLEAR_NOTIFICATIONS({commit}){
      commit('clearNotifications');
    },
    _tick({ state, commit, dispatch }){
      //iterate list backwards, preserving lower indexes as items are spliced out
      for(let i = state.notifications.length - 1; i >= 0; i--){
        let age = Date.now() - state.notifications[i].age;
        if(age >= NOTIFICATION_DURATION) commit('removeNotification', state.notifications[i].key);
      }

      setTimeout(()=>{ dispatch('_tick'); }, 250);
    },
    NAVIGATE({ commit }, page){
      commit;
      commit('setPageTitle', '');
      router.push(page);
    },
    SET_PAGE_TITLE({ commit }, title){
      commit('setPageTitle', title);
    },
    PROMPT({ commit }, { message="", title="", placeholder="" }){
      let dialog = { message, title, placeholder, type: 'prompt' };
      let promise = null;
      let callback = (p) => { promise = p};
      commit('queueDialog', { dialog, callback });
      return promise;
    },
    PROMPT_TEXTAREA({ commit }, { message="", title="", placeholder="" }){
      let dialog = { message, title, placeholder, type: 'prompt_textarea' };
      let promise = null;
      let callback = (p) => { promise = p};
      commit('queueDialog', { dialog, callback });
      return promise;
    },
    ALERT({ commit }, { message="", title="", level="info" }){
      let dialog = { message, title, level, type: 'alert' };
      let promise = null;
      let callback = (p) => { promise = p};
      commit('queueDialog', { dialog, callback });
      return promise;
    },
    CONFIRM({ commit }, { message="", title="", yes="Yes", no="No" }){
      let dialog = { message, title, yes, no, type: 'confirm' };
      let promise = null;
      let callback = (p) => { promise = p};
      commit('queueDialog', { dialog, callback });
      return promise;
    },
    DIALOG_ADVANCE_QUEUE({ commit, state }){
      let queuedItem = state.dialogQueue[0];
      commit('dialogAdvanceQueue');
      return queuedItem;
    },
    TOGGLE_EDUCATION({ commit }){
      commit('toggleEducation');
    },
    init({dispatch}){ dispatch('_tick'); }
  }
};

export default UI;
